<template>
  <div
    :class="[
      'competition-configuration-priority-field',
      { 'competition-configuration-priority-field--variant-table': variant === 'table' },
      { 'competition-configuration-priority-field--variant-modal': variant === 'modal' },
    ]"
  >
    <input
      ref="inputReference"
      :class="[
        'competition-configuration-priority-field__input',
        { 'competition-configuration-priority-field__input--is-invalid': isValueInvalid },
      ]"
      type="text"
      :value="currentValue"
      :disabled="isDisabled"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onUnfocus"
      @input="setSafeValue($event.target.value)"
    >
  </div>
</template>

<script>
import {
  includes,
  isNil,
  split,
  join,
  drop,
} from 'lodash';
import {
  ref,
  computed,
  onMounted,
} from 'vue';

export default {
  props: {
    variant: {
      type: String,
      required: true,
      validator: (value) => includes(['table', 'modal'], value),
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    isPlaceholder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const inputReference = ref();
    const currentValue = ref('');
    const isInputFocused = ref(false);
    const isValueInvalid = computed(() => {
      if (props.isPlaceholder) return false;
      const currentValueAsString = `${currentValue.value}`;
      const isValueEmpty = !currentValueAsString.length;
      const containsMoreThanDigits = !/^\d*$/.test(currentValueAsString);
      const containsLeadingZero = currentValueAsString.startsWith('0') && currentValueAsString.length > 1;
      const numberOutOfBounds = +currentValueAsString < props.min || +currentValueAsString > props.max;
      return isValueEmpty || containsMoreThanDigits || containsLeadingZero || numberOutOfBounds;
    });
    const placeholder = computed(() => (isInputFocused.value ? '' : '-'));
    const isDisabled = computed(() => props.disabled || isNil(props.modelValue));

    const setCurrentValue = (newCurrentValue) => {
      currentValue.value = null;
      currentValue.value = newCurrentValue;
    };
    const setModelValue = (newModelValue) => {
      setCurrentValue(newModelValue);
      emit('update:modelValue', newModelValue);
    };
    const resetCurrentValue = () => {
      setCurrentValue(isNil(props.modelValue) ? '' : props.modelValue);
    };
    const setSafeValue = (newValue) => {
      const containsMoreThanDigits = !/^\d*$/.test(newValue);
      const containsLeadingZero = newValue.startsWith('0') && newValue.length > 1;

      if (containsLeadingZero) {
        setCurrentValue(join(drop(split(newValue, '')), ''));
        return;
      }

      if (containsMoreThanDigits) {
        setCurrentValue(currentValue.value);
        return;
      }

      setCurrentValue(newValue);
    };

    const onFocus = () => {
      isInputFocused.value = true;
      if (inputReference.value) {
        inputReference.value.select();
      }
    };
    const onUnfocus = () => {
      isInputFocused.value = false;

      if (props.variant === 'modal') {
        setModelValue(currentValue.value);
        return;
      }

      if (isValueInvalid.value || +props.modelValue === +currentValue.value) return;
      setModelValue(currentValue.value);
    };

    onMounted(resetCurrentValue);

    return {
      inputReference,
      currentValue,
      isValueInvalid,
      isInputFocused,
      placeholder,
      isDisabled,
      resetCurrentValue,
      setSafeValue,
      onFocus,
      onUnfocus,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration-priority-field {
  width: 100%;
  height: 100%;

  &--variant-table {
    .competition-configuration-priority-field__input {
      background-color: #fff;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #191414;
      width: 100%;
      height: 100%;
      padding: 8px;

      &::placeholder {
        color: #191414;
      }

      &:focus {
        box-shadow: 0px 0px 0px 2px #A2BFFF;
        outline: none;
      }
    }
  }

  &--variant-modal {
    .competition-configuration-priority-field__input {
      width: 100%;
      padding: 8px;
      border-radius: var(--radius-medium, 4px);
      border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
      background: var(--neutral-bg-default-resting, #FFF);
      height: 32px;
      font-size: 14px;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        box-shadow: 0px 0px 0px 2px #A2BFFF;
        outline: none;
      }
    }
  }

  &__input {
    &--is-invalid {
      box-shadow: 0px 0px 0px 2px #FF2E2D !important;
    }
  }
}
</style>
